import { Box, Container, Tab, Tabs, Typography } from "@material-ui/core"
import { GeelyCarModelResponse } from "api"
import BottomButtons from "common/BottomButtons"
import BottomText from "common/BottomText"
import { Desktop } from "common/MediaQueries"
import NextImage from "common/NextImage"
import React, { useState } from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`
const StyledImageContainer = styled.div`
  min-height: 300px;
  height: 300px;
  position: relative;
  display: inline-block;

  ${(p) => p.theme.breakpoints.up("md")} {
    min-height: 45%;
  }
  :after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 50%;
    display: inline-block;

    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 15%,
      #ffffff 95%
    );
    ${(p) => p.theme.breakpoints.up("md")} {
      height: 40%;
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 25%,
        rgba(255, 255, 255, 0.97) 80%,
        #ffffff 95%
      );
    }
  }
`

const StyledImage = styled.div<{ $inView: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;
  /* ${(p) =>
    !p.$inView
      ? `transform: translateY(-100px); opacity: 0;`
      : `translateY(0); opacity: 1;`} */
`

const StyledTabs = styled(Tabs)`
  margin: 0 20px;

  min-height: 35px;
  .MuiTab-root {
    line-height: 1;
    height: 35px;
    min-height: 35px;
  }
  .MuiTabs-indicator {
    background-color: ${(p) => p.theme.colors.mainBlue};
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    .MuiTab-root {
      height: 50px;
    }

    .MuiTabs-flexContainer {
      justify-content: center;
      > button {
        margin: 0 5px;
        overflow: visible;
        &:after {
          content: "|";
          position: absolute;
          left: -5px;
        }
        &:last-child:after {
          content: none;
        }
      }
    }
  }
`
const StyledTab = styled(Tab)<{ $selected: boolean }>`
  font-weight: ${(p) => (p.$selected ? "500" : "normal")};
  color: ${(p) =>
    p.$selected ? p.theme.colors.midnight : p.theme.colors.battleshipGrey};
`

const StyledTabsContainer = styled.div`
  min-height: 40%;
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-shrink: 1;
  }
`
const StyledTabPanel = styled(TabPanel)``
const StyledPanelContainer = styled(Container).attrs({ component: "h2" })`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: 240px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    justify-content: center;
    padding-top: 60px;
  }
`

const StyledSubtitle = styled(Typography).attrs({
  component: "span",
})`
  font-size: 26px;
  margin-bottom: 10px;
  color: ${(p) => p.theme.colors.midnight};
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 38px;
    max-width: 450px;
    margin-bottom: 10px;
  }
`
const StyledDescription = styled(Typography).attrs({
  component: "span",
})`
  font-size: 14px;
  color: ${(p) => p.theme.colors.greyishBrown};
  line-height: 1.43;
  ${(p) => p.theme.breakpoints.up("md")} {
    max-width: 600px;
    line-height: 1.78;
    margin-right: 30px;
  }
`
const StyledBottomTextContainer = styled.div`
  padding-bottom: 20px;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: -15px;
  }
`
interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      tabIndex={0}
      {...other}
    >
      {value === index && (
        <Box>
          <span>
            {" "}
            <StyledPanelContainer>{children} </StyledPanelContainer>
          </span>
        </Box>
      )}
    </div>
  )
}

const Safety = ({ data }: { data: GeelyCarModelResponse }) => {
  const safetyFeatures = data.safetyComponents
  const [activeImage, setActiveImage] = useState(
    safetyFeatures.length ? safetyFeatures[0].image.imageUrl : ""
  )
  const [activeImagealt, setActiveImagealt] = useState(
    safetyFeatures.length ? safetyFeatures[0].image.alt : ""
  )
  const [value, setValue] = useState(0)
  const { ref, inView } = useInView({
    threshold: 0.3,
  })

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    setActiveImage(safetyFeatures[newValue].image.imageUrl)
    setActiveImagealt(safetyFeatures[newValue].image.alt)
  }

  return (
    <StyledMainContainer ref={ref}>
      <StyledImageContainer>
        <StyledImage $inView={inView}>
          {activeImage && (
            <NextImage
              image={activeImage}
              imageSizes={"100vw"}
              alt={activeImagealt}
            />
          )}
        </StyledImage>
      </StyledImageContainer>
      <StyledTabsContainer>
        <StyledTabs
          selectionFollowsFocus
          variant="scrollable"
          scrollButtons="off"
          value={value}
          onChange={handleChange}
          aria-label="safety features"
        >
          {safetyFeatures.map((feature, index) => (
            <StyledTab
              id={`a11y-tab-${index}`}
              key={index}
              label={feature.name}
              aria-controls={`a11y-tab-${index}-tab`}
              aria-label={`${index + 1}/${safetyFeatures?.length} מספר שקופית ${
                index != value ? `,לא נבחר` : ``
              }`}
              $selected={index === value ? true : false}
            />
          ))}
        </StyledTabs>
        {safetyFeatures.map((feature, index) => (
          <StyledTabPanel
            aria-labelledby={`a11y-tab-${index}`}
            key={index}
            value={value}
            index={index}
          >
            <StyledSubtitle>{feature.mainTitle}</StyledSubtitle>
            <StyledDescription>{feature.subTitle}</StyledDescription>
          </StyledTabPanel>
        ))}
      </StyledTabsContainer>
      <Desktop>
        <BottomButtons paddingTop="0px" />
      </Desktop>
      <StyledBottomTextContainer>
        <BottomText
          align="center"
          textAlign="center"
          inputText="*המערכות הינן כלי עזר בלבד. פירוט נרחב על תכונות המערכות ופעולתן ניתן לקרא בספר הרכב"
        />
      </StyledBottomTextContainer>
    </StyledMainContainer>
  )
}

export default Safety
